import React from "react";
import TelegramIcon from '@mui/icons-material/Telegram';
import { IconButton, Link } from "@mui/material";

const Icons = () => {
    return ( 
        <div>
            <Link href="https://t.me/ineedmorelove">
                <IconButton sx={{ 
                    color: "#d7d7d7",
                    marginBottom: '10px',
                    paddingTop: '15px',
                 }}>
                    <TelegramIcon 
                        sx={{ fontSize: 20 }}  
                    />
                </IconButton>
            </Link>
        </div>
    );
};

export default Icons;
