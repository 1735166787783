import React from 'react';

function Person({ person }) {
    return (
        <div className="person-container">
            <h2 className='h2_name'>{person.name}</h2>
            {person.imageButtons.map((imageButton, index) => (
                <a key={index} href={imageButton.link} className="image-button">
                    <img src={imageButton.image} alt={imageButton.text} />
                    <h2 className="image-button-text">{imageButton.text}</h2>
                </a>
            ))}
            {person.buttons.map((button, index) => (
                <a key={index} href={button.link} className="button-link">
                    <button className="button">
                        <div className='button_text'>{button.text}</div>
                        {button.image && <img src={button.image} alt={button.text} className="button-image" />}
                    </button>
                </a>
            ))}
        </div>
    );
}

export default Person;